
.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.p-2 {
  padding: 2rem;
}
.img-container{
  -webkit-mask-image: url("../../Images/blob.svg");
  -webkit-mask-repeat: no-repeat ;
}
