
.me{

    height: 300px;

}
.intro{
    width: 55%;
    margin: auto;
}
.profile-about {
  grid-area: about;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 600px) {
    .intro {
      width: 100%;
    }
  } 