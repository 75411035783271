body {
  background: linear-gradient(to right , #5F7161, #6D8B74,  #D0C9C0);
    color: white;
    margin: 0;
    text-align: center;
    font-family: 'Merriweather', serif;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  h1 {
    color: white;
    font-size: 5.00rem;
    margin: 10px auto 0 auto;
    font-family: 'Sacramento', cursive;
  }
  
  h2 {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 2.0rem;
    font-weight: normal;
    padding-bottom: 10px;
  }
  
  h3 {
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
  h6{
    /* line-height: 2; */
    font-family: 'Montserrat', sans-serif;
  }
  
  p {
    line-height: 2;
    font-family: 'Montserrat', sans-serif;
  }
  
  hr {
    border: dotted #EAF6F6 6px;
    border-bottom: none;
    width: 4%;
    margin: 100px auto;
  }
  
  a{
    color: white;
    font-family: 'Montserrat', sans-serif;
    margin: 10px 20px;
    text-decoration: none;
  }
  
  a:hover {
    color: #EAF6F6;
  }
  .profile-grid {
    display: grid;
    grid-template-areas:
      'top top'
      'about about'
      'experience experience'
      'projects projects'
      'tech tech'
      'contact contact';
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 1rem;
  }
  .my-1 {
    margin: 1rem 0;
  }


  @media only screen and (max-width: 600px) {
    .profile-grid {
      display: grid;
      grid-template-areas:
        'top '
        'about '
        'experience '
        'projects '
        'tech '
        'contact ';
    width: 100%;

    justify-content: center;
    align-items: center;
    text-align: center;
      grid-gap: 1rem;
    }
    }