
.profile-skills{
  grid-area: tech;
}

.skills-grid{
  display: grid;
  grid-template-areas:
   "auto auto auto  ";
  justify-content: center;

}

.frontEnd-grid {
  grid-area: front;
}

.skills-card{
  /* width: min(100%,500px); */
  width: 550px;
    height: 300px;
    margin: 16px 0 30px;
    border-radius: 10px;
    padding: 10px 8px 40px;
    padding: 10px;

  display: grid;
  grid-template-columns: auto auto auto auto auto auto ;
  grid-template-rows: 90px 90px 90px 90px 90px;
  gap: 7px;
}

.skills-card > div {

  text-align: center;

  font-size: 40px;
}
.icon{
  height: 80px;
  width: 80px;
}

@media only screen and (max-width: 600px) {

  .skills-card{
    width: 250px;
    display: grid;
    height: 500px;
    grid-template-columns: auto auto auto auto  ;
    gap: 7px;
    justify-content: center;
    margin: 0;
  }
 

}